<template>
  <!-- page2 -->
  <div class="width-100 height-100">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="card1 animate__animated font-nt">
      <Head :title="src_title"></Head>
      <div class="content">
        <div class="dec none">
          {{ sdate[0] }}，北上资金实际{{ lrlc[0]
          }}<span class="color_y" id="num1">{{ fshszbuyamt1[0] }}</span
          ><span class="color_y">亿元</span>。近10个交易日中北上资金有{{
            jlr[0]
          }}天为净流入，累计净流入额为<span  :class="{
              color_y: fshszbuyamt2[0] > 0,
              color_b: fshszbuyamt2[0] < 0,
            }" id="num2">{{
            fshszbuyamt2[0]
          }}</span
          ><span :class="{
              color_y: fshszbuyamt2[0] > 0,
              color_b: fshszbuyamt2[0] < 0,
            }">亿元</span>
        </div>
        <div class="legend none">
          <div class="legend1">
            <div><img src="../../assets/img/nt/legend16_1.png" alt="" /></div>

            <p>沪股通</p>
          </div>
          <div class="legend2">
            <div><img src="../../assets/img/nt/legend16_2.png" alt="" /></div>
            <p>深股通</p>
          </div>
          <div class="legend3">
            <div>
              <img src="../../assets/img/nt/legend16_3.png" alt="" />
            </div>
            <p>合计（亿元）</p>
          </div>
        </div>
        <div class="chart" id="chart1"></div>
        <div class="infoSource">数据来源：公开信息或第三方</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, rowToColumn } from "@/utils/common";
import Head from "./head.vue";
import src_title from "@/assets/img/nt/title16.png";
// import barChartHorizen from "@/components/barChartHorizen/index_v1.0.vue";

export default {
  name: "TwoComponent",
  props: ["cardData", "tempAduioArr"],
  components: { Head },
  data: function () {
    return {
      src_title,
      sdate: [],
      lrlc: [],
      fshszbuyamt1: [],
      jlr: [],
      fshszbuyamt2: [],
      fsh_30: [],
      fsz_30: [],
      total_30: [],
      trade_date: [],
      min:"",
      audiosrc:""
    };
  },
  watch: {
    cardData: {
      handler() {
        this.sdate = this.cardData.chart_data_14[0].data.sdate;
        this.lrlc = this.cardData.chart_data_14[0].data.lrlc;
        this.fshszbuyamt1 = this.cardData.chart_data_14[0].data.fshszbuyamt1;
        this.jlr = this.cardData.chart_data_14[0].data.jlr;
        this.fshszbuyamt2 = this.cardData.chart_data_14[0].data.fshszbuyamt2;

        this.fsh_30 = this.cardData.chart_data_14[1].data.fsh_30;
        this.fsz_30 = this.cardData.chart_data_14[1].data.fsz_30;
        this.total_30 = this.cardData.chart_data_14[1].data.total_30;
        this.trade_date = this.cardData.chart_data_14[1].data.trade_date;

        let arr=[Math.min.apply(null, this.fsh_30),Math.min.apply(null, this.fsz_30),Math.min.apply(null, this.total_30)]
        this.min=(Math.min(...arr)-50).toFixed(0)
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    playIn2(refName) {
      this.$refs[refName].BarChart(this.Value, {
        x: (d) => d.value,
        y: (d) => d.label,
        height: 1000,
        width: 981,
        marginLeft: 160,
        marginRight: 140,
        duration: 400,
        delay: 400,
        yPadding: 0.4,
        ease: "easeBack",
        numberPosition: "outside",
      });
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
       this.audiosrc = this.tempAduioArr[14].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });
      setTimeout(() => {
        //  $(".card1").addClass("animate__animated animate__fadeOut");
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 4;
      let audioTime = Math.ceil(this.tempAduioArr[14].audio_length);
      if (audioTime > 4) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[14].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
       $(".card1").addClass("animate__animated animate__fadeIn");
      $(".dec").removeClass("none");
         $(".dec").addClass("animate__animated animate__fadeIn");
      this.playNumVaryIn("#num1");
      this.playNumVaryIn("#num2");
      await sleep(1000);
      $(".legend").removeClass("none");
      await sleep(1000);
      this.loadChart1();
    },
    loadChart1() {
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      myChart.setOption({
        grid: {
          left: "90",
          right: "60",
          bottom: "150",
          top: "50",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "category",
            data: this.trade_date,
            axisLabel: {
              textStyle: {
                fontSize: 30,
                color: "#fff",
              },
              margin: 40,
              align: "center",
              showMaxLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min:this.min,
            axisLabel: {
              textStyle: {
                fontSize: 33,
                color: "#fff",
              },
            },
            splitLine: {
              show: true,
              interval: "auto",
              lineStyle: {
                type: "dashed",
                color: ["#fff"],
                width:2,
                opacity:0.8
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.fsh_30,
            itemStyle: {
              normal: {
                color: function (params) {
                  var index_color = params.value;
                  if (index_color > 0) {
                    return "#FF9F45";
                  } else {
                    return "#5E9535";
                  }
                },
              },
            },
          },
          {
            type: "bar",
            data: this.fsz_30,
            itemStyle: {
              normal: {
                color: function (params) {
                  var index_color = params.value;
                  if (index_color > 0) {
                    return "#FFC48D";
                  } else {
                    return "#43DE9C";
                  }
                },
              },
            },
          },
          {
            type: "line",
            data: this.total_30,
            lineStyle: {
              color: "#fff",
              width: 3,
            },
            symbol: "circle",
            symbolSize: 15,
            itemStyle: {
              color: "#fff",
            },
          },
        ],
      });
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}
.content {
  position: absolute;
  top: 176px;
  left: 30px;
  width: 1020px;
  height: 1581px;
  .dec {
    position: absolute;
     top: 261px;
    left: 74px;
    // height: 203px;
    width: 876px;
    background-color: #d52e38;
    opacity: 0.9;
    // background: url("../../assets/img/nt/box4.png");
    color: #fff;
    font-size: 33px;
    padding: 23px 40px;
    box-sizing: border-box;
    line-height: 45px;
  }
  .legend {
    position: absolute;
    top: 505px;
    left: 100px;
    display: flex;
    font-size: 33px;
    color: #fff;
    width: 680px;
    justify-content: space-around;
  }
  .legend1,
  .legend2,
  .legend3 {
    display: flex;
    width: 171px;
    justify-content: space-around;
  }
  .legend3 {
    width: 250px;
  }
  .color_y {
    color: #ffff06;
  }
  .color_b {
    color: #43de9c;
  }
  .suffix {
    font-size: 33px;
    color: #ffffff;
    position: absolute;
    top: 415px;
    left: 231px;
  }
  #chart1 {
   height: 940px;
    width: 920px;
    position: absolute;
    top: 570px;
    left: 48px;
  }
  .none{
    visibility: hidden;
  }

  .infoSource {
    width: 100%;
    font-size: 35px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: -29px;
    text-align: center;
  }
}
</style>

