<template>
  <!-- page2 -->
  <div class="width-100 height-100">
    <div class="card1 animate__animated font-nt">
      <div class="content">
        <div class="logo">
          <img src="../../assets/img/nt/ctlogo.png" alt="" />
        </div>

        <div class="infoSource">
          <p>本视频内容仅供参考，不构成投资意见</p>
          <p >数据来源：公开信息或第三方</p>
          <p>官方客服电话：95399</p>
          <p style="margin-left:30px">股市有风险 投资需谨慎 请理性使用融资融券工具</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, rowToColumn } from "@/utils/common";

export default {
  name: "TwoComponent",
  props: ["cardData", "tempAduioArr"],
  data: function () {
    return {};
  },
  watch: {
    cardData: {
      handler() {},
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 2;
      return {
        pageLen: time,
      };
    },
    async playPage1() {
       $(".card1").addClass("animate__animated animate__fadeIn");
      $(".dec").removeClass("none");
         $(".dec").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}

.logo {
  position: absolute;
  top: 608px;
  left: 170px;
  img{
width: 743px;
  height: 212px;
  }
  
}
 .infoSource {
    width: 100%;
    font-size: 35px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: 53px;
    text-align: center;
    line-height: 50px;
    p:nth-of-type(2){
        margin-bottom: 44px;
    }
  }
</style>

