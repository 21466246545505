<template>
  <!-- page2 -->
  <div class="width-100 height-100">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="card1 animate__animated font-nt none" v-show="currentPage == 1">
      <Head :title="src_title"></Head>
      <div class="content">
        <div class="dec none">
          {{ chart_data_2.trading_day[0] }}，两市融资融券余额<span
            class="color_y"
            id="num1"
            >{{ chart_data_2.tradingvalue[0] }}</span
          ><span class="color_y">亿元</span>， 较上日{{ chart_data_2.cha[0]
          }}<span
            :class="{
              color_y: chart_data_2.cha[0] === '增加',
              color_b: chart_data_2.cha[0] === '减少',
            }"
            >{{ chart_data_2.tradingvaluese[0] }}</span
          ><span
            :class="{
              color_y: chart_data_2.cha[0] === '增加',
              color_b: chart_data_2.cha[0] === '减少',
            }"
            >亿元</span
          >。市场融资余额<span class="color_y" id="num2">{{
            chart_data_2.financevalue[0]
          }}</span
          ><span class="color_y">亿元</span>，较上日{{ chart_data_2.cha1[0]
          }}<span
            :class="{
              color_y: chart_data_2.cha1[0] === '上涨',
              color_b: chart_data_2.cha1[0] === '下降',
            }"
            >{{ chart_data_2.financevaluese[0] }}%</span
          >，融资买入额为<span class="color_y" id="num3">{{
            chart_data_2.financebuyvalue[0]
          }}</span
          ><span class="color_y">亿元</span>，较上日{{ chart_data_2.cha2[0]
          }}<span
            :class="{
              color_y: chart_data_2.cha2[0] === '上涨',
              color_b: chart_data_2.cha2[0] === '下降',
            }"
            >{{ chart_data_2.financebuyvaluese[0] }}%</span
          >，融资净买入<span
            :class="{
              color_y: chart_data_2.financebuyval[0] > 0,
              color_b: chart_data_2.financebuyval[0] < 0,
            }"
            id="num4"
            >{{ chart_data_2.financebuyval[0] }}</span
          ><span
            :class="{
              color_y: chart_data_2.financebuyval[0] > 0,
              color_b: chart_data_2.financebuyval[0] < 0,
            }"
            >亿元</span
          >
        </div>
        <div class="chart1_name none">两市融资余额及增速</div>
        <div id="chart1"></div>
        <div class="legend none">
          <div class="icon1">
            <img src="../../assets/img/nt/icon1.png" alt="" />
            市场融资余额(百亿元)
          </div>
          <div class="icon2">
            <img src="../../assets/img/nt/icon2.png" alt="" />
            融资余额增速(右轴)
          </div>
        </div>
        <div class="chart2_name none">融资买入额（亿元）</div>
        <div id="chart2"></div>
        <div class="infoSource">数据来源：公开信息或第三方</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import Head from "./head.vue";
import src_title from "@/assets/img/nt/title4.png";

export default {
  name: "TwoComponent",
  data: function () {
    return {
      currentPage: 1,
      src_title,
      chart_data_2: {},
      chart1_x: [],
      chart1_y1: [],
      chart1_y2: [],
      chart2_x: [],
      chart2_y: [],
      max_y2: "",
      min_y2: "",
      max_y1: "",
      min_y1: "",
      max_y: "",
      min_y: "",
      audiosrc: "",
    };
  },
  components: { Head },
  props: ["cardData", "tempAduioArr"],
  computed: {},
  mounted() {
    // this.loadChart1();
    // this.loadChart2();
  },
  watch: {
    cardData: {
      handler() {
        if (!this.cardData) return;
        this.chart_data_2 = this.cardData.chart_data_2[0].data;
        this.chart1_x = this.cardData.chart_data_2[1].data.tradingday;
        this.chart1_y1 = this.cardData.chart_data_2[1].data.financevalue;
        this.chart1_y2 = this.cardData.chart_data_2[1].data.financevaluebe;
        this.chart2_x = this.cardData.chart_data_2[2].data.tradingday;
        this.chart2_y = this.cardData.chart_data_2[2].data.financebuyvalue;

        this.max_y2 = Math.max.apply(null, this.chart1_y2);
        this.min_y2 = Math.min.apply(null, this.chart1_y2);
        // console.log(this.min_y2);
        this.max_y1 = Math.max.apply(null, this.chart1_y1);
        this.min_y1 = Math.min.apply(null, this.chart1_y1);
        this.max_y = Math.max.apply(null, this.chart2_y);
        this.min_y = Math.min.apply(null, this.chart2_y);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    rowToColumn(data) {
      if (!data) return [];
      const keys = Object.keys(data).filter((key) => Array.isArray(data[key]));
      if (keys.length === 0) return [];
      const len = data[keys[0]].length;
      return Array.from({ length: len }).map((_, index) => {
        return keys.reduce((t, key) => {
          t[key] = data[key] && data[key][index];
          return t;
        }, {});
      });
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[2].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[2].audio_length);
      if (audioTime > 5) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[2].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
      $(".card1").removeClass("none");
      $(".card1").addClass("animate__animated animate__fadeIn");
      $(".dec").removeClass("none");
      $(".dec").addClass("animate__animated animate__fadeIn");
      this.playNumVaryIn("#num1");
      this.playNumVaryIn("#num2");
      this.playNumVaryIn("#num3");
      this.playNumVaryIn("#num4");
      await sleep(1000);
      $(".chart1_name").removeClass("none");
      $(".chart1_name").addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.loadChart1();
      $(".legend").removeClass("none");
      $(".legend").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".chart2_name").removeClass("none");
      $(".chart2_name").addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.loadChart2();
    },
    // 两市融资余额及增速
    loadChart1() {
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: "%",
          right: "30",
          top: "10",
          textStyle: {
            color: "#fff",
            lineHeight: 40,
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chart1_x,
          position: "bottom",
          axisTick: {
            show: true,
            inside: true, //y轴坐标点消失
          },
          axisLabel: {
            align: "right",
            textStyle: {
              fontSize: 27,
              color: "#fff",
            },
            margin: 15,
            interval: 60,
            showMaxLabel: true,
            formatter: function (val, index) {
              if (index == 0) {
                val = 0;
              } else {
                return val;
              }
            },
          },
          axisLine: {
            show: true,
            onZero: false,
            lineStyle: {
              color: "#fff",
              width: 2,
            },
          },
        },
        legend: {
          data: [],
          left: 0,
        },
        animationDuration: 4000,
        grid: {
          top: 70,
          left: 70,
          right: 70,
          bottom: 40,
          show: true,
          borderWidth: "0.5",
          color: ["#FFD2AC"],
        },
        yAxis: [
          {
            type: "value",
            boundaryGap: [0, "100%"],
            position: "left",
            max: this.max_y1.toFixed(0),
            min: this.min_y1.toFixed(0) - 5,
            axisLabel: {
              textStyle: {
                fontSize: 24,
                color: "#fff",
              },
            },
            splitLine: {
              show: true,
              interval: "auto",
              lineStyle: {
                type: "dashed",
                color: ["#fff"],
                width: 2,
                opacity: 0.8,
              },
            },
            // axisLine: {
            //   lineStyle: {
            //     color: "#FFD2AC",
            //     width: 1,
            //   },
            // },
          },
          {
            type: "value",
            boundaryGap: [0, "100%"],
            position: "right",
            max: this.max_y2.toFixed(0),
            min: this.min_y2.toFixed(0)-0.5,
            axisLabel: {
              textStyle: {
                fontSize: 24,
                color: "#fff",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: ["#ECA2A3"],
              },
            },
            axisLine: {
              lineStyle: {
                color: "#FFD2AC",
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: 0.6,
            symbol: "none",
            lineStyle: {
              color: "#FFDD00",
              width: 2,
            },
            color: "#FFDD00",
            data: this.chart1_y1,
            label: {
              show: true,
            },
            areaStyle: {
              background: "#FFDD00",
              opacity: "0.3",
            },
            yAxisIndex: 0,
          },
          {
            type: "line",
            symbol: "none",
            lineStyle: {
              color: "#FFD9D5",
              width: 2,
            },
            data: this.chart1_y2,
            // label: {
            //   show: true,
            // },
            yAxisIndex: 1,
          },
        ],
      });
    },
    loadChart2() {
      let myChart = this.$echarts.init(document.getElementById("chart2"));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chart2_x,
          axisTick: {
            show: true,
            inside: true, //y轴坐标点消失
          },
          axisLabel: {
            align: "right",
            textStyle: {
              fontSize: 27,
              color: "#fff",
            },
            margin: 15,
            interval: 60,
            showMaxLabel: true,
            formatter: function (val, index) {
              if (index == 0) {
                val = 0;
              } else {
                return val;
              }
            },
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
              width: 2,
            },
          },
        },
        legend: {
          data: [],
          left: 0,
        },
        animationDuration: 4000,
        grid: {
          top: 13,
          left: 50,
          right: 100,
          bottom: 40,
          show: true,
          borderWidth: "0.5",
          color: ["#FFD2AC"],
        },
        yAxis: [
          {
            type: "value",
            name: "",
            // boundaryGap: [0, "100%"],
            position: "right",
            max: this.max_y.toFixed(0),
            min: this.min_y.toFixed(0)-200,
            axisLabel: {
              textStyle: {
                fontSize: 24,
                color: "#fff",
              },
            },
            splitLine: {
              show: true,
              interval: "auto",
              lineStyle: {
                type: "dashed",
                color: ["#fff"],
                width: 2,
                opacity: 0.8,
              },
            },
            axisLine: {
              lineStyle: {
                color: "#FFD2AC",
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: 0.6,
            symbol: "none",
            lineStyle: {
              color: "#FFDD00",
              width: 2,
            },
            color: "#FFDD00",
            data: this.chart2_y,
            label: {
              show: true,
            },
            areaStyle: {
              background: "#FFDD00",
              opacity: "0.3",
            },
          },
        ],
      });
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}
.none {
  visibility: hidden;
}
.content {
  position: absolute;
  top: 176px;
  left: 30px;
  width: 1020px;
  height: 1581px;
  .dec {
    position: absolute;
    top: 261px;
    left: 74px;
    // height: 203px;
    width: 876px;
    background-color: #d52e38;
    opacity: 0.9;
    // background: url("../../assets/img/nt/box4.png");
    color: #fff;
    font-size: 33px;
    padding: 20px 35px;
    box-sizing: border-box;
    line-height: 45px;
  }
  .color_y {
    color: #ffff06;
    // margin: 10px;
  }
  .color_b {
    color: #43de9c;
  }
  .chart1_name {
    position: absolute;
    top: 515px;
    left: 318px;
    font-size: 41px;
    color: #ffffff;
  }
  #chart1 {
    height: 400px;
    width: 950px;
    position: absolute;
    top: 537px;
    left: 36px;
  }
  #chart2 {
    height: 350px;
    width: 990px;
    position: absolute;
    bottom: 100px;
    left: 10px;
  }
  .legend {
    display: flex;
    color: #fff;
    font-size: 29px;
    width: 907px;
    justify-content: space-around;
    position: absolute;
    top: 955px;
    left: 67px;
  }
  .chart2_name {
    position: absolute;
    top: 1055px;
    left: 318px;
    font-size: 41px;
    color: #ffffff;
  }

  .infoSource {
    font-size: 35px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: -29px;
    left: 287px;
  }
}
</style>

