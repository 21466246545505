<template>
  <!-- page2 -->
  <div class="width-100 height-100">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="card1 animate__animated font-nt none">
      <Head :title="src_title"></Head>
      <div class="content">
        <div class="dec none">
          {{ extra_param_2[0] }}，沪深两市合计成交额<span
            class="color_y"
            id="num1"
            >{{ extra_param_2[1] }}</span
          ><span class="color_y">亿元</span> 上涨：<span
            class="color_y"
            id="num2"
            >{{ extra_param_2[2] }}</span
          ><span class="color_y">只</span> 下跌：<span
            class="color_b"
            id="num3"
            >{{ extra_param_2[3] }}</span
          ><span class="color_b">只</span>
        </div>
        <div class="upDowns">
          <ul class="index" v-if="indexData.length > 0">
            <li
              class="s_index none"
              v-for="item in indexData"
              :key="item.secuabbr"
              :id="'li' + item.id"
            >
              <div class="left">
                <div class="index_name">{{ item.secuabbr }}</div>
                <div
                  class="index_value"
                  :class="item.y > 0 ? 'rise' : 'drop'"
                  :id="'number' + item.id"
                >
                  {{ item.x }}
                </div>
              </div>
              <div class="right">
                <img
                  src="../../assets/img/nt/up.png"
                  v-if="item.y > 0"
                  alt=""
                  :id="'img' + item.id"
                  :class="item.y > 0 ? 'rise' : 'drop'"
                />
                <img
                  src="../../assets/img/nt/down.png"
                  v-else-if="item.y < 0"
                  alt=""
                  :id="'img' + item.id"
                  :class="item.y > 0 ? 'rise' : 'drop'"
                />
                <div class="num" :class="item.y > 0 ? 'rise' : 'drop'">
                  <span :id="'change' + item.id">{{ item.y }}</span
                  ><span>%</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="infoSource">数据来源：公开信息或第三方</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import Head from "./head.vue";
import src_title from "@/assets/img/nt/title2.png";

export default {
  name: "TwoComponent",
  data: function () {
    return {
      src_title,
      extra_param_2: [],
      indexData: {},
      audiosrc: "",
    };
  },
  components: { Head },
  props: ["cardData", "tempAduioArr"],
  computed: {},
  watch: {
    cardData: {
      handler() {
        if (!this.cardData) return;
        console.log(this.cardData);
        if (!this.cardData.extra_param_2) return;
        console.log(this.cardData.extra_param_2);
        this.extra_param_2 = this.cardData.extra_param_2;
        if (!this.cardData.chart_data_1[0]) return;
        this.indexData = this.rowToColumn(this.cardData.chart_data_1[0].data);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    rowToColumn(data) {
      if (!data) return [];
      const keys = Object.keys(data).filter((key) => Array.isArray(data[key]));
      if (keys.length === 0) return [];
      const len = data[keys[0]].length;
      return Array.from({ length: len }).map((_, index) => {
        return keys.reduce((t, key) => {
          t[key] = data[key] && data[key][index];
          return t;
        }, {});
      });
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      console.log(this.tempAduioArr[1]);

      if (!this.tempAduioArr[1]) return;

      this.audiosrc = this.tempAduioArr[1].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);
      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 3;
      if (!this.tempAduioArr[1]) return;
      let audioTime = Math.ceil(this.tempAduioArr[1].audio_length);
      if (audioTime > 3) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[1].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
      $(".card1").removeClass("none");
      $(".card1").addClass("animate__animated animate__fadeIn");
      $(".dec").removeClass("none");
      $(".dec").addClass("animate__animated animate__fadeIn");
      await sleep(100);
      this.playNumVaryIn("#num1");
      this.playNumVaryIn("#num2", 0);
      this.playNumVaryIn("#num3", 0);
      await sleep(1000);
      $("#li1").removeClass("none");
      console.log(this.indexData[0]);
      if (this.indexData[0].y > 0) {
        $("#li1").addClass("animate__animated animate__fadeInUp");
      } else {
        $("#li1").addClass("animate__animated animate__fadeInDown");
      }

      this.playNumVaryIn("#number1");
      this.playNumVaryIn("#change1");
      await sleep(500);
      $("#li2").removeClass("none");

      if (this.indexData[1].y > 0) {
        $("#li2").addClass("animate__animated animate__fadeInUp");
      } else {
        $("#li2").addClass("animate__animated animate__fadeInDown");
      }
      this.playNumVaryIn("#change2");
      this.playNumVaryIn("#number2");
      await sleep(500);
      $("#li3").removeClass("none");

      if (this.indexData[2].y > 0) {
        $("#li3").addClass("animate__animated animate__fadeInUp");
      } else {
        $("#li3").addClass("animate__animated animate__fadeInDown");
      }

      this.playNumVaryIn("#change3");
      this.playNumVaryIn("#number3");
      await sleep(500);
      $("#li4").removeClass("none");

      if (this.indexData[3].y > 0) {
        $("#li4").addClass("animate__animated animate__fadeInUp");
      } else {
        $("#li4").addClass("animate__animated animate__fadeInDown");
      }

      this.playNumVaryIn("#change4");
      this.playNumVaryIn("#number4");
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}
.none {
  visibility: hidden;
  // display: none;
}
.content {
  position: absolute;
  top: 176px;
  left: 30px;
  width: 1020px;
  height: 1581px;

  .dec {
    position: absolute;
    top: 252px;
    left: 75px;
    // height: 159px;
    width: 876px;
    background-color: #d52e38;
    opacity: 0.9;
    // background: url("../../assets/img/nt/box3.png");
    color: #fff;
    font-size: 33px;
    padding: 28px 35px;
    box-sizing: border-box;
    line-height: 55px;
    .color_y {
      color: #ffff06;
      margin: 10px;
    }
    .color_b {
      color: #43de9c;
    }
  }
  .upDowns {
    width: 907px;
    position: absolute;
    top: 520px;
    left: 41px;
    .index {
      li {
        height: 162px;
        display: flex !important;
        display: -webkit-flex;
        justify-content: space-between;
        margin-bottom: 83px;
        padding: 0 32px;

        .left {
          .index_name {
            height: 57px;
            font-size: 41px;
            color: #fff;
          }

          .index_value {
            font-size: 101px;
            height: 149px;
            font-weight: bold;
          }
        }

        .right {
          width: 209px;
          height: 145px;
          position: relative;
          padding: 23px 0px;
          .num {
            position: absolute;
            left: 60%;
            bottom: 0;
            transform: translate(-50%, 0);
            font-size: 62px;
            font-weight: bold;
          }
        }
      }
    }
    .rise {
      color: #fa5437;
      // opacity: 0.88;
      text-shadow: 6px 6px 12px rgba(126, 1, 1, 0.85);
    }

    .drop {
      color: #43de9c;
      // opacity: 0.78;
      text-shadow: 6px 6px 12px rgba(143, 0, 0, 0.88);
    }
  }
  .infoSource {
    font-size: 35px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: -29px;
    left: 287px;
  }
}
</style>

