<template>
  <!-- page2 -->
  <div class="width-100 height-100 content" id="card1">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="card1 animate__animated font-nt none" v-show="currentPage == 1">
      <Head :title="src_title"></Head>
      <div class="dec">
        <p class="title">中国首提建设新能源供给消纳体系</p>
        <p class="text1">
          中国将加速建设新能源供给消纳体系，并积极有序发展光能源、硅能源、氢能源、可再生能源。据新华社报道，中共中央政治局1月24日就努力实现碳达峰碳中和目标进行第三十六次集体学习。中共中央总书记习近平在会上提出，为推进“双碳”工作，须加强统筹协调、推动能源革命、推进产业优化升级、加快绿色低碳科技革命等。
        </p>
        <p class="text1 txt2">
          中国将加速建设新能源供给消纳体系，并积极有序发展光能源、硅能源、氢能源、可再生能源。据新华社报道，中共中央政治局1月24日就努力实现碳达峰碳中和目标进行第三十六次集体学习。中共中央总书记习近平在会上提出，为推进“双碳”工作，须加强统筹协调、推动能源革命、推进产业优化升级、加快绿色低碳科技革命等。
        </p>
      </div>
      <div class="infoSource">数据来源：公开信息或第三方</div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import Head from "./head.vue";
import src_title from "@/assets/img/nt/title1.png";

export default {
  name: "TwoComponent",
  props: ["cardData", "tempAduioArr"],
  data: function () {
    return {
      currentPage: 1,
      src_title,
      audiosrc: "",
    };
  },
  watch: {
    cardData() {
      const data = this.cardData;
      this.extra_param_1 = data.extra_param_1[0];
    },
    tempAduioArr() {
      this.audiosrc = this.tempAduioArr[0].audio_link;
    },
  },
  components: { Head },
  computed: {},
  methods: {
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      if (!this.tempAduioArr[0]) return;
      this.audiosrc = this.tempAduioArr[0].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);
      await this.playPage1();
    },
    async playPage1() {
      $(".card1").removeClass("none");
      $(".card1").addClass("animate__animated animate__fadeIn");
    },
    getTime() {
      // 默认返回5秒
      let time = 10;
      if (!this.tempAduioArr[0]) return;
      let audioTime = Math.ceil(this.tempAduioArr[0].audio_length);
      if (audioTime > 10) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[0].audio_link,
        pageLen: time,
      };
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}
.dec {
  width: 911px;
  position: absolute;
  top: 450px;
  left: 100px;
  color: #fff;
  .title {
    font-size: 59px;
    font-weight: bold;
    color: #fff;
    opacity: 0.9;
    margin-bottom: 47px;
    width: 886px;
    height: 84px;
    text-align: center;
  }
  .text1 {
    font-size: 45px;
    opacity: 0.85;
    margin-bottom: 45px;
    margin-right: 15px;
    line-height: 60px;
  }
}
.infoSource {
  font-size: 35px;
  color: #ffffff;
  opacity: 0.5;
  position: absolute;
  bottom: 140px;
  left: 319px;
}
</style>

