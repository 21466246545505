<template>
  <!-- page2 -->
  <div class="width-100 height-100">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="card1 animate__animated font-nt">
      <Head :title="src_title"></Head>
      <div class="content">
        <div class="dec">行业板块方面，{{ jss[0] }}</div>
        <div class="chart">
          <bar-chart-horizen
            ref="refBarChartExtra"
            id="refBarChartExtra"
            customClass="c_bar2"
          ></bar-chart-horizen>
        </div>
        <div class="infoSource">本视频板块数据来源申万一级行业</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, rowToColumn } from "@/utils/common";
import Head from "./head.vue";
import src_title from "@/assets/img/nt/title9.png";
import barChartHorizen from "./barChartHorizen/index_v1.0.vue";

export default {
  name: "TwoComponent",
  props: ["cardData", "tempAduioArr"],
  components: { Head, barChartHorizen },
  data: function () {
    return {
      src_title,
      industry: "",
      industryRank: [],
      jss: [],
      secuabbr2: "",
      changepct: [],
      upDowns: [],
      upDown: [],
      audiosrc:"",
      market2: [
        { label: "节能风电", value: 5.13 },
        { label: "立讯精密", value: 4.13 },
        { label: "方大炭素", value: 3.13 },
        { label: "格林美", value: 1.13 },
        { label: "节能风电2", value: 0.13 },
        { label: "立讯精密2", value: -5.13 },
        { label: "方大炭素2", value: -6.13 },
        { label: "格林美2", value: -7.13 },
        { label: "节能风电3", value: -8.13 },
        { label: "立讯精密3", value: -9.13 },
      ],
    };
  },
  watch: {
    cardData: {
      handler() {
        this.jss = this.cardData.chart_data_7[0].data.nmae;
        this.changepct = rowToColumn(this.cardData.chart_data_7[1].data);
        console.log( this.changepct);
        this.upDowns = this.changepct.map((item) => {
          return {
            label: item.secuabbr1,
            value: Number(item.changepct1),
          };
        });
        this.upDowns.sort(function (a, b) {
          return b.value - a.value;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    playInExtra(refName) {
      this.$refs[refName].BarChart(this.upDowns, {
        x: (d) => d.value,
        y: (d) => d.label,
        height: 1000,
        width: 981,
        marginLeft: 200,
        marginRight: 180,
        duration: 400,
        delay: 400,
        yPadding: 0.5,
        ease: "easeExp",
        numberSuffix: "%",
        numberPosition: "outside",
        // labelPosition: "top",
      });
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
     this.audiosrc = this.tempAduioArr[7].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });
      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[7].audio_length);
      if (audioTime > 5) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[7].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
       $(".card1").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      this.playInExtra("refBarChartExtra");
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}
.content {
  position: absolute;
  top: 176px;
  left: 30px;
  width: 1020px;
  height: 1581px;
  .dec {
    position: absolute;
    top: 261px;
    left: 74px;
    // height: 203px;
    width: 876px;
    background-color: #d52e38;
    opacity: 0.9;
    // background: url("../../assets/img/nt/box4.png");
    color: #fff;
    font-size: 33px;
    padding: 23px 40px;
    box-sizing: border-box;
    line-height: 45px;
  }
  .color_y {
    color: #ffff06;
    margin: 10px;
  }
  .color_b {
    color: #43de9c;
  }
  .chart {
    .c_bar2 {
      height: 1100px;
      width: 981px;
      position: absolute;
      top: 480px;
      left: 19px;
      //  /deep/ text {
      //   font-size: 33px;

      //    fill: #43de9c;
      //   opacity: 0.78;
      // }

      /deep/ .axis_y {
        font-size: 33px;
        .text {
          fill: #fff;
        }
      }
      /deep/ .bar_negative {
        fill: #43de9c;
        opacity: 0.78;
      }
      /deep/ .reverse {
        font-size: 33px;
        fill: RGBA(97, 173, 122, 1);
      }
      /deep/ .bar_positive {
        fill: #ff9f45;
      }
      /deep/ .numbers_positive {
        fill: #ff9f45;
        font-size: 36px;
        font-weight: bold;
      }
      /deep/ .numbers_negative {
        fill: RGBA(97, 173, 122, 1);
        // opacity: 0.78;
        font-size: 36px;
        font-weight: bold;
      }
    }
  }
  .infoSource {
    width: 100%;
    font-size: 35px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: -29px;
    text-align: center;
  }
}
</style>

