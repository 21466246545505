<template>
  <!-- page2 -->
  <div class="width-100 height-100">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="card1 animate__animated font-nt">
      <Head :title="src_title"></Head>
      <div class="content">
        <div class="dec none" id="dec">
          行业方面，融资余额居前行业包括{{ industry[0]
          }}<span class="color_y"
            >（<span class="color_y" id="num1">{{ industryValue[0] }}</span
            ><span class="color_y">亿元</span>）</span
          >、{{ industry[1]
          }}<span class="color_y"
            >（<span class="color_y" id="num2">{{ industryValue[1] }}</span
            ><span class="color_y">亿元</span>）</span
          >、{{ industry[2]
          }}<span class="color_y"
            >（<span class="color_y" id="num3">{{ industryValue[2] }}</span
            ><span class="color_y">亿元</span>）</span
          >等
        </div>
        <div class="chart">
          <tree-map
            ref="refTreeMap1"
            id="refTreeMap1"
            customClass="c_tree_map1"
          />
        </div>

        <div class="infoSource">本视频板块数据来源申万一级行业</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, rowToColumn } from "@/utils/common";
import Head from "./head.vue";
import src_title from "@/assets/img/nt/title6.png";
import treeMap from "@/components/treeMap/index.vue";

export default {
  name: "TwoComponent",
  props: ["cardData", "tempAduioArr"],
  data: function () {
    return {
      src_title,
      industry: "",
      industryRank: [],
      industryValue: [],
      audiosrc: "",
    };
  },
  watch: {
    cardData: {
      handler() {
        this.industry = this.cardData.chart_data_4[0].data.firstindustryname;
        this.industryValue = this.cardData.chart_data_4[2].data.financevalue;

        this.firstindustry = rowToColumn(this.cardData.chart_data_4[1].data);
        this.industryRank = this.firstindustry.map((item) => {
          return {
            name: `flare.${item.firstindustryname}`,
            size: Number(item.financevalue),
          };
        });

        this.industryRank.unshift({ name: "flare", size: null });
        // console.log(this.industryRank);
      },
      deep: true,
      immediate: true,
    },
  },
  components: { Head, treeMap },
  computed: {},
  methods: {
    playIn1(refName) {
      this.$refs[refName].TreeMap(this.industryRank, {
        value: (d) => d?.size,
        width: 965,
        height: 1120,
        valueSuffix: "亿",
        padding: 8,
        rectRadius: 4,
        marginLeft: 9,
        marginRight: 80,
        marginTop: 10,
        marginBottom: 30,
        fontSizeMap: [24, 53],
        colors: [
          "#D14740",
          "#D14740",
          "#D14740",
          "#D14740",
          "#DC6A5C",
          "#DC6A5C",
          "#DC6A5C",
          "#E68875",
          "#E68875",
          "#FFD2AC",
        ],
        duration: 400,
        delay: 20,
        ease: "easeCircleInOut",
      });
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[4].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });
      
      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 6;
      let audioTime = Math.ceil(this.tempAduioArr[4].audio_length);
      if (audioTime > 6) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[4].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
      $(".card1").addClass("animate__animated animate__fadeIn");
      $(".dec").removeClass("none");
      $(".dec").addClass("animate__animated animate__fadeIn");
      this.playNumVaryIn("#num1");
      this.playNumVaryIn("#num2");
      this.playNumVaryIn("#num3");
      await sleep(1000);
      this.playIn1("refTreeMap1");
    
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}
.content {
  position: absolute;
  top: 176px;
  left: 30px;
  width: 1020px;
  height: 1581px;
  .dec {
    position: absolute;
    top: 256px;
    left: 75px;
    // height: 203px;
    width: 876px;
    background-color: #d52e38;
    opacity: 0.9;
    // background: url("../../assets/img/nt/box4.png");
    color: #fff;
    font-size: 33px;
        padding: 6px 18px;
    box-sizing: border-box;
    line-height: 55px;
  }

  .chart {
    .c_tree_map1 {
      display: inline-block;
      height: 1070px;
      width: 981px;
      position: absolute;
      top: 445px;
    left: 65px;
      margin-bottom: 20px;
      // height:1070px ;
      // width: 981px;
      /deep/ .txt1 {
        font-weight: bold;
      }
      /deep/ .txt2 {
        font-weight: bold;
      }
      /deep/ a:first-of-type .txt1 {
        fill: #fff;
        font-size: 48px;
      }
      /deep/ a:first-of-type .txt2 {
        fill: #fff;
        font-size: 48px;
      }
      /deep/ a:nth-of-type(2) .txt1 {
        fill: #fff;
        font-size: 48px;
      }
      /deep/ a:nth-of-type(2) .txt2 {
        fill: #fff;
        font-size: 48px;
      }
      /deep/ a:nth-of-type(3) .txt1 {
        fill: #fff;
        font-size: 48px;
      }
      /deep/ a:nth-of-type(3) .txt2 {
        fill: #fff;
        font-size: 45px;
      }
      /deep/ a:nth-of-type(4) .txt1 {
        fill: #fff;
        font-size: 40px;
      }
      /deep/ a:nth-of-type(4) .txt2 {
        fill: #fff;
        font-size: 40px;
      }
      /deep/ a:nth-of-type(5) .txt1 {
        fill: #fff;
        font-size: 40px;
      }
      /deep/ a:nth-of-type(5) .txt2 {
        fill: #fff;
        font-size: 40px;
      }
      /deep/ a:nth-of-type(6) .txt1 {
        fill: #fff;
        font-size: 40px;
      }
      /deep/ a:nth-of-type(6) .txt2 {
        fill: #fff;
        font-size: 40px;
      }
      /deep/ a:nth-of-type(7) .txt1 {
        fill: #fff;
        font-size: 36px;
      }
      /deep/ a:nth-of-type(7) .txt2 {
        fill: #fff;
        font-size: 36px;
      }
      /deep/ a:nth-of-type(8) .txt1 {
        fill: #fff;
        font-size: 36px;
      }
      /deep/ a:nth-of-type(8) .txt2 {
        fill: #fff;
        font-size: 36px;
      }
      /deep/ a:nth-of-type(9) .txt1 {
        fill: #fff;
        font-size: 36px;
      }
      /deep/ a:nth-of-type(9) .txt2 {
        fill: #fff;
        font-size: 36px;
      }
      /deep/ a:nth-of-type(10) .txt1 {
        fill: #c11a24;
        font-size: 24px;
      }
      /deep/ a:nth-of-type(10) .txt2 {
        fill: #c11a24;
        font-size: 24px;
      }
    }
  }

  .infoSource {
    width: 100%;
    font-size: 35px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: -29px;
    // left: 287px;
    text-align: center;
  }
  .color_y {
    color: #ffff06;
  }
  .color_b {
    color: #43de9c;
  }
}
</style>

