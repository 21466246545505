var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"width-100 height-100"},[_c('audio',{ref:"audio",attrs:{"src":_vm.audiosrc}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage == 1),expression:"currentPage == 1"}],staticClass:"card1 animate__animated font-nt none"},[_c('Head',{attrs:{"title":_vm.src_title}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"dec none"},[_vm._v(" "+_vm._s(_vm.chart_data_2.trading_day[0])+"，两市融资融券余额"),_c('span',{staticClass:"color_y",attrs:{"id":"num1"}},[_vm._v(_vm._s(_vm.chart_data_2.tradingvalue[0]))]),_c('span',{staticClass:"color_y"},[_vm._v("亿元")]),_vm._v("， 较上日"+_vm._s(_vm.chart_data_2.cha[0])),_c('span',{class:{
            color_y: _vm.chart_data_2.cha[0] === '增加',
            color_b: _vm.chart_data_2.cha[0] === '减少',
          }},[_vm._v(_vm._s(_vm.chart_data_2.tradingvaluese[0]))]),_c('span',{class:{
            color_y: _vm.chart_data_2.cha[0] === '增加',
            color_b: _vm.chart_data_2.cha[0] === '减少',
          }},[_vm._v("亿元")]),_vm._v("。市场融资余额"),_c('span',{staticClass:"color_y",attrs:{"id":"num2"}},[_vm._v(_vm._s(_vm.chart_data_2.financevalue[0]))]),_c('span',{staticClass:"color_y"},[_vm._v("亿元")]),_vm._v("，较上日"+_vm._s(_vm.chart_data_2.cha1[0])),_c('span',{class:{
            color_y: _vm.chart_data_2.cha1[0] === '上涨',
            color_b: _vm.chart_data_2.cha1[0] === '下降',
          }},[_vm._v(_vm._s(_vm.chart_data_2.financevaluese[0])+"%")]),_vm._v("，融资买入额为"),_c('span',{staticClass:"color_y",attrs:{"id":"num3"}},[_vm._v(_vm._s(_vm.chart_data_2.financebuyvalue[0]))]),_c('span',{staticClass:"color_y"},[_vm._v("亿元")]),_vm._v("，较上日"+_vm._s(_vm.chart_data_2.cha2[0])),_c('span',{class:{
            color_y: _vm.chart_data_2.cha2[0] === '上涨',
            color_b: _vm.chart_data_2.cha2[0] === '下降',
          }},[_vm._v(_vm._s(_vm.chart_data_2.financebuyvaluese[0])+"%")]),_vm._v("，融资净买入"),_c('span',{class:{
            color_y: _vm.chart_data_2.financebuyval[0] > 0,
            color_b: _vm.chart_data_2.financebuyval[0] < 0,
          },attrs:{"id":"num4"}},[_vm._v(_vm._s(_vm.chart_data_2.financebuyval[0]))]),_c('span',{class:{
            color_y: _vm.chart_data_2.financebuyval[0] > 0,
            color_b: _vm.chart_data_2.financebuyval[0] < 0,
          }},[_vm._v("亿元")])]),_c('div',{staticClass:"chart1_name none"},[_vm._v("两市融资余额及增速")]),_c('div',{attrs:{"id":"chart1"}}),_vm._m(0),_c('div',{staticClass:"chart2_name none"},[_vm._v("融资买入额（亿元）")]),_c('div',{attrs:{"id":"chart2"}}),_c('div',{staticClass:"infoSource"},[_vm._v("数据来源：公开信息或第三方")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"legend none"},[_c('div',{staticClass:"icon1"},[_c('img',{attrs:{"src":require("../../assets/img/nt/icon1.png"),"alt":""}}),_vm._v(" 市场融资余额(百亿元) ")]),_c('div',{staticClass:"icon2"},[_c('img',{attrs:{"src":require("../../assets/img/nt/icon2.png"),"alt":""}}),_vm._v(" 融资余额增速(右轴) ")])])}]

export { render, staticRenderFns }