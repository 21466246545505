<template>
  <!-- page2 -->
  <div class="width-100 height-100">
      <audio :src="audiosrc" ref="audio"></audio>
    <div class="card1 animate__animated font-nt">
      <Head :title="src_title"></Head>
      <div class="content">
        <div class="dec none">
        融券净卖出额居前个股包括{{ secuabbr[0] }}、{{ secuabbr[1] }}、{{ secuabbr[2] }}等
        </div>
        <div class="suffix none">融券净卖出额（亿元）</div>
        <div class="chart" id="chart1">
          <!-- <bar-chart-horizen
            ref="refBarChart2"
            id="refBarChart2"
            customClass="c_bar2"
          ></bar-chart-horizen> -->
        </div>
        <div class="infoSource">本视频内个股数据均来自沪深市场</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, rowToColumn } from "@/utils/common";
import Head from "./head.vue";
import src_title from "@/assets/img/nt/title14.png";
// import barChartHorizen from "@/components/barChartHorizen/index_v1.0.vue";

export default {
  name: "TwoComponent",
  props: ["cardData", "tempAduioArr"],
  components: { Head },
  data: function () {
    return {
      src_title,
      secuabbr: [],
      financevalues: [],
      audiosrc:""
    };
  },
  watch: {
    cardData: {
      handler() {
        this.secuabbr = this.cardData.chart_data_12[0].data.secuabbr;
        // this.val = rowToColumn(this.cardData.chart_data_9[1].data);
        // this.Value = this.val.map((item) => {
        //   return {
        //     label: item.secuabbr,
        //     value: Number(item.financevaluerefu),
        //   };
        // });
        this.chart_y = this.cardData.chart_data_12[1].data.secuabbr;
        this.chart_x = this.cardData.chart_data_12[1].data.securitysellvo;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    playIn2(refName) {
      this.$refs[refName].BarChart(this.Value, {
        x: (d) => d.value,
        y: (d) => d.label,
        height: 1000,
        width: 981,
        marginLeft: 160,
        marginRight: 140,
        duration: 400,
        delay: 400,
        yPadding: 0.4,
        ease: "easeBack",
        numberPosition: "outside",
      });
    },
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[12].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });
      setTimeout(() => {
        //  $(".card1").addClass("animate__animated animate__fadeOut");
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
     getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[12].audio_length);
      if (audioTime > 5) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[12].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
       $(".card1").addClass("animate__animated animate__fadeIn");
      $(".dec").removeClass("none");
         $(".dec").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".suffix").removeClass("none");
      // this.playIn2("refBarChart2");
      this.loadChart1();
    },
    loadChart1() {
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      myChart.setOption({
        grid: {
          left: "160",
          right: "130",
          bottom: "50",
          top: 20,
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show: false,
            axisLine: { show: false },
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.chart_y,
            axisLine: { show: false, onZero: true },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
               margin: 150,
              textStyle: {
                fontSize: 33,
                color: "#fff",
                align: "left",
              },
                 formatter: function (params) {
                var maxLength = 5;
                //判断长度，超出使用...代替
                if (params && params.length > maxLength) {
                  return params.substring(0, maxLength - 1) + "...";
                } else {
                  return params;
                }
              },
            },
          },
        ],
        series: [
          {
            inverse: true,
            type: "bar",
            data: this.chart_x,
            barWidth: 50,
            itemStyle: {
              normal: {
                color: "#FF9F45",
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  // formatter: function (value) {
                  //   return "-" + value.data;
                  // },
                  textStyle: {
                    //数值样式
                    color: "#fff",
                    fontSize: 33,
                  },
                },
              },
            },
            // showBackground: true,
          },
        ],
      });
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}
.content {
  position: absolute;
  top: 176px;
  left: 30px;
  width: 1020px;
  height: 1581px;
  .dec {
    position: absolute;
    top: 261px;
    left: 74px;
    // height: 203px;
    width: 876px;
    background-color: #d52e38;
    opacity: 0.9;
    // background: url("../../assets/img/nt/box4.png");
    color: #fff;
    font-size: 33px;
    padding: 23px 40px;
    box-sizing: border-box;
    line-height: 55px;
  }
  .color_y {
    color: #ffff06;
    margin: 10px;
  }
  .color_b {
    color: #43de9c;
  }
  .suffix {
    font-size: 33px;
    color: #ffffff;
    position: absolute;
    top: 472px;
    left: 231px;
  }
  #chart1 {
    height: 1050px;
    width: 900px;
    position: absolute;
    top: 500px;
    left: 58px;
  }
  .chart {
    .c_bar2 {
      height: 1070px;
      width: 981px;
      position: absolute;
      top: 456px;
      left: 19px;
      /deep/ .axis_y {
        font-size: 33px;
        .text {
          fill: #fff;
        }
      }
      /deep/ .bar_negative {
        fill: #43de9c;
        opacity: 0.78;
      }
      /deep/ .bar_positive {
        fill: #ff9f45;
      }
      /deep/ .numbers_positive {
        fill: #ff9f45;
        font-size: 36px;
        font-weight: bold;
      }
      /deep/ .numbers_negative {
        fill: RGBA(97, 173, 122, 1);
        // opacity: 0.78;
        font-size: 36px;
        font-weight: bold;
      }
    }
  }
  .infoSource {
    width: 100%;
    font-size: 35px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: -29px;
    text-align: center;
  }
}
</style>

