<template>
  <!-- page2 -->
  <div class="width-100 height-100">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="card1 animate__animated font-nt">
      <Head :title="src_title"></Head>
      <div class="content">
        <div class="news">
          <div class="news_title">广誉远：独立董事接受纪律审查和监察调查</div>
          <div class="dec">
            广誉远(600771)1月27日晚间公告，独立董事王斌全涉嫌严重违纪违法，目前正接受山西省纪委监委纪律审查和监察调查。
          </div>
        </div>
        <div class="news news2">
          <div class="news_title">广誉远：独立董事接受纪律审查和监察调查</div>
          <div class="dec">
            广誉远(600771)1月27日晚间公告，独立董事王斌全涉嫌严重违纪违法，目前正接受山西省纪委监委纪律审查和监察调查。
          </div>
        </div>
        <div class="news news3">
          <div class="news_title">广誉远：独立董事接受纪律审查和监察调查</div>
          <div class="dec">
            广誉远(600771)1月27日晚间公告，独立董事王斌全涉嫌严重违纪违法，目前正接受山西省纪委监委纪律审查和监察调查。
          </div>
        </div>

        <div class="infoSource">数据来源：公开信息或第三方</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, rowToColumn } from "@/utils/common";
import Head from "./head.vue";
import src_title from "@/assets/img/nt/title17.png";

export default {
  name: "TwoComponent",
  props: ["cardData", "tempAduioArr"],
  components: { Head },
  data: function () {
    return {
      src_title,
      secuabbr: [],
      financevalues: [],
      audiosrc: "",
    };
  },
  watch: {
    cardData: {
      handler() {},
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[15].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });
      setTimeout(() => {
        //  $(".card1").addClass("animate__animated animate__fadeOut");
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      // let time = 4;
      let time = 10;
      let audioTime = Math.ceil(this.tempAduioArr[15].audio_length);
      if (audioTime > 10) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[15].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
      $(".card1").addClass("animate__animated animate__fadeIn");
      $(".dec").removeClass("none");
      $(".dec").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}
.content {
  position: absolute;
  top: 176px;
  left: 30px;
  width: 1020px;
  height: 1581px;
  .news {
    position: absolute;
    top: 280px;
    left: 66px;
    margin-bottom: 102px;
    .news_title {
      color: #fff;
      font-weight: bold;
      font-size: 45px;
      opacity: 0.9;
      margin-bottom: 20px;
    }
    .dec {
      font-size: 36px;
      width: 920px;
      color: #fff;
      opacity: 0.6;
      line-height: 60px;
    }
  }
  .news2 {
    top: 635px;
  }
  .news3 {
    top: 1029px;
  }

  .infoSource {
    width: 100%;
    font-size: 35px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: -25px;
    text-align: center;
  }
}
</style>

