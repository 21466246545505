<template>
  <!-- page1 -->
  <div class="width-100 height-100 content" id="card1">
    <!-- <div class="audio_box none">
      <audio ref="audio1" src="https://res.idtcdn.com/Cube/video-config/con-46a8e87e.mp3"></audio>
      <audio ref="audio2" src="https://res.idtcdn.com/Cube/video-config/con-770eb442.mp3"></audio>
      <audio ref="audio3" src="https://res.idtcdn.com/Cube/video-config/con-e4acdab7.mp3"></audio>
      <audio ref="audio4" src="https://res.idtcdn.com/Cube/video-config/con-cb25aee5.mp3"></audio>
      <audio ref="audio5" src="https://res.idtcdn.com/Cube/video-config/con-da37143c.mp3"></audio>
      <audio ref="audio6" src="https://res.idtcdn.com/Cube/video-config/con-31be51c0.mp3"></audio>
      <audio ref="audio7" src="https://res.idtcdn.com/Cube/video-config/con-a0e53a04.mp3"></audio>
      <audio ref="audio8" src="https://res.idtcdn.com/Cube/video-config/con-e8cf494e.mp3"></audio>
    </div> -->
    <div class="card card1 animate__animated font-nt">
      <div class="logo_nt">
        <img src="../../assets/img/nt/ctlogo1.png" alt="" />
      </div>
      <div>
        <div class="title_box">
          <img src="../../assets/img/nt/title-box.png" alt="" />
        </div>
        <div class="time_title">{{ extra_param_1 }}</div>
        <div class="title">
          <img src="../../assets/img/nt/card-title.png" alt="" />
        </div>
      </div>
      <div class="foot">
        <p>官方客服电话：95399</p>
        <p>股市有风险 投资需谨慎 请理性使用融资融券工具</p>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";

export default {
  name: "OneComponent",
  data: function () {
    return {
      extra_param_1: "",
    };
  },
  props: ["cardData", "tempAduioArr"],
  computed: {},
  watch: {
    cardData() {
      const data = this.cardData;
      this.extra_param_1 = data.extra_param_1[0];
    },
  },
  methods: {
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    async playPage1() {
      // await sleep(1000)
      // $(".card1").addClass("animate__animated animate__fadeOut");
    },
    getTime() {
      // 默认返回5秒
      let time = 1;
      return {
        pageLen: time,
      };
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
}
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
  .time_title {
    width: 100%;
    font-size: 60px;
    position: absolute;
    top: 775px;
    left: 391px;
    color: #ffffff;
    letter-spacing: 2px;
    width: 720px;
    margin: 0 auto;
  }
  .title {
    position: absolute;
    top: 609px;
    left: 197px;
  }
  .title_box {
    position: absolute;
    top: 773px;
    left: 338px;
  }
  .foot {
    position: absolute;
    bottom: 53px;
    left: 178px;
    text-align: center;
    color: #ffffff;
    font-size: 35px;
    opacity: 0.5;
    p:first-of-type {
      margin-bottom: 7px;
    }
  }
}
.logo_nt {
  position: absolute;
  top: 92px;
  right: 72px;
  img {
    width: 425px;
    height: 122px;
  }
}
</style>

