<template>
  <!-- page2 -->
  <div class="width-100 height-100">
    <div class="card1 animate__animated font-nt">
      <Head :page="pageNum"></Head>
      <div class="content">
        <div class="title1">
          <img src="../../assets/img/nt/title19_1.png" alt="" />
        </div>
        <div class="dec1">
          融资融券业务属于中高风险业务，请确保您的风险承受能力等级符合该业务风险等级匹配要求。建议您审慎评估该业务特征及风险，自行做出投资决定，并承担相应风险。
        </div>
        <div class="title2">
          <img src="../../assets/img/nt/title19_2.png" alt="" />
        </div>
        <div class="dec2">
          本系列视频内容不构成任何投资意见及建议，视频所涉及相关信息均源自于市场公开渠道，本公司对这些信息的准确性或完整性不作保证，亦不对因使用该等信息而引发或可能引发的损失承担任何责任。本系列视频最终解释权归诚通证券股份有限公司所有。
        </div>

        <div class="infoSource">数据来源：公开信息或第三方</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, rowToColumn } from "@/utils/common";
import Head from "./head.vue";

export default {
  name: "TwoComponent",
  props: ["cardData", "tempAduioArr"],
  components: { Head },
  data: function () {
    return {
      secuabbr: [],
      financevalues: [],
      pageNum: 18,
    };
  },
  watch: {
    cardData: {
      handler() {},
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      setTimeout(() => {
        //  $(".card1").addClass("animate__animated animate__fadeOut");
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 2;
      return {
        pageLen: time,
      };
    },
    async playPage1() {
       $(".card1").addClass("animate__animated animate__fadeIn");
      $(".dec").removeClass("none");
         $(".dec").addClass("animate__animated animate__fadeIn");
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  height: 1920px;
  background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  background-size: cover;
}
.content {
  position: absolute;
  top: 176px;
  left: 30px;
  width: 1020px;
  height: 1581px;
  .title1 {
    position: absolute;
    top: 213px;
    left: 357px;
  }
  .dec1 {
        width: 890px;
    height: 269px;
    font-size: 45px;
    color: #ffffff;
    opacity: 0.9;
    position: absolute;
    top: 350px;
    left: 89px;
    line-height: 65px;
  }
  .title2 {
    position: absolute;
    top: 809px;
    left: 357px;
  }
  .dec2 {
    width: 890px;
    height: 269px;
    font-size: 45px;
    color: #ffffff;
    opacity: 0.9;
    position: absolute;
    bottom: 360px;
    left: 89px;
    line-height: 65px;
  }

  .infoSource {
    width: 100%;
    font-size: 35px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: -19px;
    text-align: center;
  }
}
</style>

