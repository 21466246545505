<template>
  <div class="common font-nt">
    <div class="header">
      <div class="left">
        <img src="../../assets/img/nt/phone.png" alt="" />
      </div>
      <div class="right">
        <img src="../../assets/img/nt/ctlogo1.png" alt="" />
      </div>
    </div>
    <div class="content">
      <div class="content_box">
        <img src="../../assets/img/nt/content_box2.png" alt="" />
      </div>
      <div class="top_title">
        <img :src="title" alt="" />
      </div>
      <div class="top_time" v-if="page !== 18">{{ extra_param_1 }}</div>
      <div class="footer">股市有风险 投资需谨慎 请理性使用融资融券工具</div>
    </div>
  </div>
</template>

<script>
import ApiServe from "@/api/index";
export default {
  name: "Head",
  props: {
    title: {
      type: String,
    },
    page: {
      type: Number,
    },
  },
  data() {
    return {
      extra_param_1: "",
    };
  },
  mounted() {
    let params = {
      model_id: 83,
    };

    ApiServe.getDataPC(params).then(({ data, code }) => {
      let Data = data;
      this.extra_param_1 = Data.extra_param_1[0];
    });
  },
};
</script>

<style lang="less" scope>
.common {
  .header {
    .left {
      position: absolute;
      left: 67px;
      top: 92px;
    }
    .right {
      position: absolute;
      right: 67px;
      top: 91px;
      img {
        width: 425px;
        height: 122px;
      }
    }
  }
  .content {
    .content_box {
      position: absolute;
      left: 63px;
      top: 221px;
      background: url("../../assets/img/nt/content_box2.png") no-repeat center
        center;
    }
    .top_title {
      width: 100%;
      position: absolute;
      // left: 390px;
      top: 269px;
      text-align: center;
    }
    .top_time {
      position: absolute;
      left: 441px;
      // text-align: center;
      top: 356px;
      font-size: 39px;
      font-family: "Source Han Sans CN";
      color: #ffffff;
      opacity: 0.7;
    }
  }
  .footer {
    font-size: 35px;
    font-family: PingFang SC;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    left: 179px;
    bottom: 53px;
  }
}
</style>